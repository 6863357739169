import {WabelEntity} from './wabel-entity.abstract';

export class CSMContact extends WabelEntity {
    constructor(obj: any) {
        super(obj);
    }

    id: string;
    displayedName: string;
    signature: string;
}
