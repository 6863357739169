import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {Event} from "./event";
import {Member} from "./member";
import {Summit} from "./summit";
import {File} from "./file";
import {Sponsor} from "./sponsor";
import {Meeting} from "./meeting";
import {EventMeetingConfirmation} from "./event_meeting_confirmation";
import {EventPackage} from "./event_package";
import {CSMContact} from './csm_contact';

export class EventParticipation extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.company) this.company = new Company(obj.company);
            if (obj.certainEvent) this.event = new Event(obj.certainEvent);
            if (obj.administrativeContact) this.administrativeContact = new Member(obj.administrativeContact);
            if (obj.primaryWabelContact) this.primaryWabelContact = new Member(obj.primaryWabelContact);
            if (obj.summits && obj.summits.items) this.summits = obj.summits.items.map(s => new Summit(s));
            if (obj.memberRegistrations && obj.memberRegistrations.count) this.memberRegistrationsCount = obj.memberRegistrations.count;
            if (obj.sponsor) { this.sponsor = new Sponsor(this.sponsor); }
            if (obj.meetingWithMyCompany) { this.meetingWithMyCompany = new Meeting(this.meetingWithMyCompany); }
            if (obj.myEventMeetingConfirmation) { this.myEventMeetingConfirmation = new EventMeetingConfirmation(this.myEventMeetingConfirmation); }
            if (obj.eventPackage) {
                this.eventPackage = new EventPackage(obj.eventPackage);
            }
            this.csmContact = obj.csmContact ? new CSMContact(obj.csmContact) : null;
        }
    }

    event: Event;
    company: Company;
    participantNumber: number;
    meetingsNumber: number;
    memberRegistrationsCount: number = 0;
    administrativeContact: Member;
    primaryWabelContact: Member;
    numberOfRegistrationsByCompany: number;
    allBadgesUrl: string;
    summits: Summit[] = [];
    canSeeAgenda: boolean;
    canReceiveRatings: boolean;
    sponsor: Sponsor;
    meetingWithMyCompany: Meeting;
    myEventMeetingConfirmation: EventMeetingConfirmation;
    eventPackage: EventPackage;
    canHaveMeetings: boolean;
    canSeeOppositeRatings: boolean;
    innovativeProductsDescription: string;
    csmContact: CSMContact;

    get id(): string {
        return this.event.eventCode + '_' + this.company.idcompany;
    }
}
